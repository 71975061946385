/* eslint-disable */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import SocialLinks from "../../components/Link";
import { ThemeContext } from "../../components/ThemeContext";

function HomePage(props) {
  const { theme, setTheme } = React.useContext(ThemeContext);
  console.log(theme);

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <div className="relative h-screen w-full">
      {theme === "dark" ? (
        <video
          className=" absolute h-screen w-full object-cover backdrop-blur-sm"
          src="https://static.videezy.com/system/resources/previews/000/054/767/original/SqueAni4krandom.mp4"
          muted
          autoPlay
          loop
        />
      ) : (
        <video
          className=" absolute h-screen w-full object-cover rotate-180 backdrop-blur-sm"
          src="/fon.mp4"
          muted
          autoPlay
          loop
        />
      )}
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center z-[2] h-full pt-8 px-4 md:flex-row">
        <div className="flex flex-col justify-center mx-4 z-[2]">
          <h2 className="text-3xl  sm:text-7xl font-bold text-white dark:text-textLight">
            I'm a Mobile and Frontend Developer
          </h2>
          <p className="text-white dark:text-textLight py-4 max-w-xl">
            Hi! my name is T. Belmiro Ramadhani but you can call me Miro, I'm a
            Mobile & Frontend Developer based in Bandung, Indonesia. I'm
            interested in mobile application and frontend development.
          </p>
          <div>
            <Link
              to="/about"
              smooth
              duration={500}
              className="group z-[2] text-white dark:text-textLight w-fit px-6 py-3 my-2 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer hover:scale-105 duration-200"
            >
              Read About Me
              <span>
                <MdOutlineKeyboardArrowRight size={25} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>

        <div className="mt-6 z-[2] shadow-md py-2 bg-primary dark:bg-primaryLight rounded-lg">
          <img
            src="https://avatars.githubusercontent.com/u/37837588?v=4"
            alt="miro's profile"
            className="rounded-2xl mx-auto w-2/3 md:w-full"
          />
        </div>
      </div>

      <SocialLinks />
    </div>
  );
}

export default HomePage;
