import React, { useState, useEffect } from "react";
import SocialLinks from "../../components/Link";
import IMG_CONST from "../../utils/imgConstant";
import BgModal from "./components/bgmodal";
import PopDetail from "./components/popdetail";
function PortoPage(props) {
  const [selectedData, setSelectedData] = useState({});
  const [show, setShow] = useState(false);
  const handleClick = (selectedId) => {
    const selectedRec = porto.find((val) => val.id === selectedId);
    setSelectedData(selectedRec);
    console.log(selectedData);
    setShow(true);
  };

  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const porto = [
    {
      id: 1,
      src: IMG_CONST.netta,
      title: "Netta",
      style: "shadow-amber-500",
      desc: "This is an attendance recording mobile and web app using a QR code that is very simple and makes it easy for us to record the attendance of the event. Made Using Flutter Cubit & reactjs redux",
      tech1: "Flutter",
      tech2: "React",
      tech3: "Tailwind",
    },
    {
      id: 2,
      src: IMG_CONST.nuby,
      title: "Nuby",
      style: "shadow-red-400",
      desc: "Mobile-based programming language learning application (Ruby) for elementary school education level. After completing all the learning, the user will be given a certificate of completion from the application.",
      tech1: "Android",
      tech2: "Firebase",
      tech3: "Java",
    },
    {
      id: 3,
      src: IMG_CONST.movie,
      title: "Movie",
      style: "shadow-red-500",
      desc: "The movie is a simple movie catalog web application that was created to learn to use redux-hooks state management.",
      tech1: "React",
      tech2: "React-redux",
      tech3: "React-bootstrap",
    },
    {
      id: 4,
      src: IMG_CONST.evote,
      title: "Evote",
      style: "shadow-green-300",
      desc: "E-vote is an application made for the housing needs of Buah Batu Regency. This application can be used for voting & disseminating information for the Buah Batu Regency residential neighborhood.",
      tech1: "Flutter",
      tech2: "BloC",
      tech3: "Cubit",
    },
    {
      id: 5,
      src: IMG_CONST.edms,
      title: "EDMS",
      style: "shadow-sky-400",
      desc: "EDMS is a reseller application & mobile-based digital marketing system for monitoring product marketing to customers.",
      tech1: "Flutter",
      tech2: "Cubit",
      tech3: "Mobile",
    },
    {
      id: 6,
      src: IMG_CONST.pertashop,
      title: "Pertashop",
      style: "shadow-lime-400",
      desc: "Pertashop is a mobile application that is used for registration & submission of maintenance related to Pertashop.  I contributed to the creation of this app while working on Datacakra / Automa.",
      tech1: "Flutter",
      tech2: "BloC",
      tech3: "Mobile",
    },
    {
      id: 7,
      src: IMG_CONST.harwat,
      title: "Harwat",
      style: "shadow-yellow-400",
      desc: "Harwat is a mobile-based application to record the history of the use of TNI-AD service vehicles for monitoring purposes.  I contributed to the creation of this app while working on Datacakra.",
      tech1: "Android",
      tech2: "Kotlin",
      tech3: "Mobile",
    },
    {
      id: 8,
      src: IMG_CONST.envirobox,
      title: "Envirobox",
      style: "shadow-sky-400",
      desc: "Envirobox is designed to deal with various environmental monitoring issues & catch problems before they arise especially in the industrial cold chain landscape. I contributed to the creation of this app while working on Datacakra.",
      tech1: "Android",
      tech2: "Java",
      tech3: "IoT",
    },
    {
      id: 9,
      src: IMG_CONST.energymeter,
      title: "Energymeter",
      style: "shadow-lime-300",
      desc: "Energymeter is an industrial-grade energy consumption monitor designed to handle a wide range of energy parameters. I contributed to the creation of this app while working on Datacakra.",
      tech1: "Android",
      tech2: "Java",
      tech3: "IoT",
    },
    {
      id: 10,
      src: IMG_CONST.presence,
      title: "Mobile Presence",
      style: "shadow-green-500",
      desc: "Employee attendance management application with body thermal scanner to overcome the covid-19. I contributed to the creation of this app while collaborating with Telkom University lecturers & Ministry of Industry Indonesia.",
      tech1: "Android",
      tech2: "Java",
      tech3: "IoT",
    },
    {
      id: 11,
      src: IMG_CONST.discuss,
      title: "Discuss",
      style: "shadow-sky-400",
      desc: "Discuss is an android-based chat & To do list application to increase the effectiveness of the work carried out by groups & disseminate information for the Information Technology and Systems division of PT. PLN WRKR.",
      tech1: "Android",
      tech2: "Firebase",
      tech3: "IoT",
    },
    {
      id: 12,
      src: IMG_CONST.sariban,
      title: "Sariban",
      style: "shadow-green-400",
      desc: "Sariban is a real-time monitoring application for the city of Bandung based on Android.",
      tech1: "Android",
      tech2: "Firebase",
      tech3: "IoT",
    },
  ];
  return (
    <div className="h-screen w-full overflow-y-scroll">
      <div className="flex flex-col h-auto mt-20 mb-10 justify-center items-start max-w-screen-lg mx-auto px-8">
        <div className="flex flex-col justify-center items-start text-white dark:text-textLight">
          <h2 className="text-3xl sm:text-5xl font-bold">My Portofolio</h2>
          <div className="w-full grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-8 text-center py-8 px-12 sm:px-0">
            {porto.map(({ id, src, title, style, desc }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 pb-4 rounded-lg ${style}`}
                onClick={() => handleClick(id, src, title, style, desc)}
              >
                <img src={src} alt={src} className="w-md mx-auto rounded-lg" />
                <p className="mt-4 font-medium">{title}</p>
              </div>
            ))}
          </div>
        </div>
        {show && <BgModal />}
        {show && <PopDetail details={selectedData} handleClose={setShow} />}
      </div>
      <SocialLinks />
    </div>
  );
}

export default PortoPage;
