import React from "react";

function Technology() {
  const techs = [
    {
      id: 1,
      src: "https://user-images.githubusercontent.com/37837588/219963014-8c864044-48f2-4f0f-a2d1-f9745a002d6b.png",
      title: "Flutter",
      style: "shadow-blue-500",
    },
    {
      id: 2,
      src: "https://user-images.githubusercontent.com/37837588/219963040-67474804-3acc-4e91-939d-60fc6602112a.png",
      title: "Angular",
      style: "shadow-red-500",
    },
    {
      id: 3,
      src: "https://cdn4.iconfinder.com/data/icons/logos-3/600/React.js_logo-512.png",
      title: "React",
      style: "shadow-blue-400",
    },
    {
      id: 4,
      src: "https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Android-256.png",
      title: "Android",
      style: "shadow-green-400",
    },
    {
      id: 5,
      src: "https://user-images.githubusercontent.com/37837588/234340130-b8041a49-fa69-4628-a3b6-d58dc7281ab5.png",
      title: "TailwindCSS",
      style: "shadow-blue-300",
    },

    {
      id: 6,
      src: "https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-original.svg",
      title: "Bootstrap",
      style: "shadow-purple-400",
    },

    {
      id: 7,
      src: "https://user-images.githubusercontent.com/37837588/212552186-2fb0c07f-d4bb-4973-81cf-afa721495e51.png",
      title: "NextJS",
      style: "dark:shadow-gray-600 shadow-white",
    },
    {
      id: 8,
      src: "https://user-images.githubusercontent.com/37837588/212552034-9c6b6a39-8619-4fee-ba57-a1c8319512d2.png",
      title: "JavaScript",
      style: "shadow-yellow-400",
    },
    {
      id: 9,
      src: "https://user-images.githubusercontent.com/37837588/212551781-e7d7a075-d424-4bcd-a543-60984fd3a37d.png",
      title: "TypeScript",
      style: "shadow-blue-400",
    },
    {
      id: 10,
      src: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/144_Gitlab_logo_logos-256.png",
      title: "Gitlab",
      style: "shadow-orange-400",
    },
    {
      id: 11,
      src: "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-social-github-256.png",
      title: "Github",
      style: "dark:shadow-gray-600 shadow-white",
    },

    {
      id: 12,
      src: "https://cdn4.iconfinder.com/data/icons/logos-brands-in-colors/3000/figma-logo-256.png",
      title: "Figma",
      style: "shadow-red-400",
    },
  ];
  return (
    <div>
      <div className="flex flex-col h-auto mt-4 mb-10 justify-center items-start max-w-screen-lg mx-auto px-8">
        <div className="flex flex-col justify-center items-start font-medium text-textLight">
          <h2 className="text-3xl sm:text-5xl font-bold text-white dark:text-textLight">
            Techonology
          </h2>
          <div className="w-full grid grid-cols-2 sm:grid-cols-5 lg:grid-cols-6 gap-8 text-center py-8 px-12 sm:px-0">
            {techs.map(({ id, src, title, style }) => (
              <div
                key={id}
                className={`shadow-md hover:scale-105 duration-500 py-2 bg-primaryLight opacity-90 rounded-lg ${style}`}
              >
                <img src={src} alt="" className="w-28 mx-auto" />
                <p className="mt-4">{title}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Technology;
