import React, { useEffect } from "react";
import Timeline from "./components/Timeline";
import GlimpseOfMe from "./components/GlimpseOfMe";
import GoldenWords from "./components/GoldenWords";
import Technology from "./components/Technology";
import SocialLinks from "../../components/Link";

function AboutPage(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <div className="h-screen w-full overflow-y-scroll pb-20">
      <GlimpseOfMe />
      <Technology />
      <Timeline />
      <GoldenWords />
      <SocialLinks />
    </div>
  );
}

export default AboutPage;
