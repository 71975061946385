import React, { useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import Toggle from "./Toggle";

const NavBar = () => {
  const [nav, setNav] = useState(false);
  const [textColor] = useState("white");

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <div className="flex justify-between items-center w-full h-20 p-4 bg-primary dark:bg-primaryLight text-white dark:text-textLight z-10 ease-in duration-300 fixed">
      <div>
        <h1 className="text-2xl font-signature ml-2">
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString("< Miro />")
                .pauseFor(1500)
                .deleteAll()
                .start();
            }}
          />
        </h1>
      </div>

      <ul style={{ color: `${textColor}` }} className="hidden sm:flex">
        <li className="p-3 font-medium text-white dark:text-textLight hover:scale-110 duration-200">
          <Link to="/">Home</Link>
        </li>
        <li className="p-3 font-medium text-white dark:text-textLight hover:scale-110 duration-200">
          <Link to="/about">About</Link>
        </li>
        <li className="p-3 font-medium text-white dark:text-textLight hover:scale-110 duration-200">
          <Link to="/portofolio">Portofolio</Link>
        </li>
        <li className="p-3 font-medium text-white dark:text-textLight hover:scale-110 duration-200">
          <Link to="/contact">Contact</Link>
        </li>
        <li className="p-2 hover:scale-110 duration-200">
          <Toggle />
        </li>
      </ul>

      {/* Mobile Button */}
      <div onClick={handleNav} className="block sm:hidden z-10">
        {nav ? (
          <AiOutlineClose size={20} style={{ color: `white dark:textLight` }} />
        ) : (
          <AiOutlineMenu size={20} style={{ color: `white dark:textLight` }} />
        )}
      </div>
      {/* Mobile Menu */}
      <div
        className={
          nav
            ? "sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-primary dark:bg-primaryLight text-center ease-in duration-300"
            : "sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-primary dark:bg-primaryLight text-center ease-in duration-300"
        }
      >
        <ul>
          <li>
            <Toggle />
          </li>
          <li
            onClick={handleNav}
            className="p-4 text-4xl text-white dark:text-textLight hover:text-gray-500"
          >
            <Link to="/">Home</Link>
          </li>
          <li
            onClick={handleNav}
            className="p-4 text-4xl text-white dark:text-textLight hover:text-gray-500"
          >
            <Link to="/about">About</Link>
          </li>
          <li
            onClick={handleNav}
            className="p-4 text-4xl text-white dark:text-textLight hover:text-gray-500"
          >
            <Link to="/portofolio">Portofolio</Link>
          </li>
          <li
            onClick={handleNav}
            className="p-4 text-4xl text-white dark:text-textLight hover:text-gray-500"
          >
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NavBar;
