import { Routes, BrowserRouter, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import AboutPage from "./pages/about/AboutPage";
import ContactPage from "./pages/contact/ContactPage";
import HomePage from "./pages/home/HomePage";
import PageNotFound from "./pages/notfound/PageNotFound";
import PortoPage from "./pages/porto/PortoPage";
import React from "react";
// import WorkPage from "./pages/work/WorkPage";

function App() {
  return (
    <div className="bg-primary dark:bg-primaryLight relative">
      <BrowserRouter>
        <Navbar />
        <Footer />
        <Routes>
          <Route path="*" element={<PageNotFound title="404 | not found" />} />
          <Route path="/" element={<HomePage title="Miro's Profile" />} />
          <Route path="/home" element={<HomePage title="Miro's Profile" />} />
          <Route path="/about" element={<AboutPage title="About | Miro" />} />

          {/* <Route path="/work" element={<WorkPage title="Work | Miro" />} /> */}
          <Route
            path="/portofolio"
            element={<PortoPage title="Portofolio | Miro" />}
          />
          <Route
            path="/contact"
            element={<ContactPage title="Contact | Miro" />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
