import React from "react";
import Typewriter from "typewriter-effect";

function GoldenWords() {
  return (
    <div>
      <div className="flex flex-col h-auto mt-20 mb-10 justify-center max-w-screen-lg mx-auto px-8">
        <div className="flex flex-col justify-center text-white dark:text-textLight">
          <h2 className="text-3xl sm:text-5xl font-bold">Golden Words</h2>
          <blockquote class="mt-4 px-4 text-xl italic font-semibold text-white dark:text-textLight">
            <svg
              aria-hidden="true"
              class="w-10 h-10 text-white dark:text-textLight"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <div className="flex flex-row justify-center items-start">
              <p className="text-white dark:text-textLight">
                <Typewriter
                  options={{
                    autoStart: true,
                    loop: true,
                  }}
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        `"Eat little at a time, but be sure to grow stronger"`
                      )
                      .pauseFor(2000)
                      .deleteAll()
                      .typeString(`"Get better, at least 1% every day"`)
                      .pauseFor(2000)
                      .deleteAll()
                      .typeString(`"Enjoy the imperfection"`)
                      .pauseFor(2000)
                      .deleteAll()
                      .typeString(`"Luck = Preparation + Opportunity"`)
                      .pauseFor(2000)
                      .deleteAll()
                      .typeString(
                        `"Opportunity is always there for those who keep trying"`
                      )
                      .pauseFor(2000)
                      .deleteAll()
                      .typeString(`"Little chances do not imply that there are none at all."`)
                      .pauseFor(2000)
                      .deleteAll()
                      .start();
                  }}
                />
              </p>
            </div>
          </blockquote>
        </div>
      </div>
    </div>
  );
}

export default GoldenWords;
