/* eslint-disable */
import React, { useEffect } from "react";

function ContactPage(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  const porto = [
    {
      id: 1,
      src: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Linkedin_unofficial_colored_svg-256.png",
      title: "LinkedIn",
      link: "https://www.linkedin.com/in/tengku-belmiro/",
      style: "shadow-blue-500 p-4",
    },
    {
      id: 2,
      src: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Instagram_colored_svg_1-256.png",
      title: "Instagram",
      link: "https://www.instagram.com/its_meerro/",
      style: "shadow-amber-500 p-4",
    },
    {
      id: 3,
      src: "https://cdn2.iconfinder.com/data/icons/social-media-2285/512/1_Twitter_colored_svg-256.png",
      title: "Twitter",
      link: "https://twitter.com/tengku_belmiro",
      style: "shadow-sky-400 p-4",
    },

    {
      id: 6,
      src: "https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-social-github-256.png",
      title: "Github",
      link: "https://github.com/castariva18",
      style: "dark:shadow-gray-600 shadow-white p-4",
    },
    {
      id: 5,
      src: "https://cdn1.iconfinder.com/data/icons/google-new-logos-1/32/gmail_new_logo-256.png",
      title: "Gmail",
      link: "mailto:tengku13elmiro@gmail.com",
      style: "shadow-red-400 p-4",
    },
    {
      id: 6,
      src: "https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/91_Discord_logo_logos-256.png",
      title: "Discord",
      link: "https://discordapp.com/users/381321655999266838",
      style: "shadow-indigo-400 p-4",
    },
  ];
  return (
    <div className="h-screen w-full overflow-hidden overflow-y-scroll">
      <div className="flex flex-col h-auto mt-24  mb-10 justify-center items-center max-w-screen-lg mx-auto px-8">
        <div className="flex flex-col justify-center items-center text-white dark:text-textLight">
          <h2 className="text-3xl sm:text-5xl font-bold">Connect with me</h2>
          <div className="w-full grid grid-cols-2  sm:grid-cols-3 lg:grid-cols-3 gap-8 text-center py-8 px-12 sm:px-0">
            {porto.map(({ id, src, title, link, style }) => (
              <a href={link} target="_blank">
                <div
                  key={id}
                  className={`shadow-md hover:scale-105 duration-500 bg-white bg-opacity-90 pb-4 rounded-lg ${style}`}
                >
                  <img
                    src={src}
                    alt={src}
                    className="w-md mx-auto rounded-lg"
                  />
                  <p className="mt-4 font-medium text-textLight">{title}</p>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
