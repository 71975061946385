/* eslint-disable */
import React from "react";

const Footer = () => {
  return (
    <div className="w-full absolute bottom-14 z-10">
      <div className="w-full flex flex-row justify-center items-center h-14 bg-primary dark:bg-primaryLight px-6 text-zinc-400 fixed z-[2]">
        {/* <div className="text-sm md:text-md">Made with 🤍</div> */}
        <div className="text-sm md:text-md">
          © 2023{" "}
          <span className="text-white dark:text-textLight font-medium">
            T.Belmiro Ramadhani
          </span>{" "}
          • All rights reserved.
        </div>
        {/* <div className="w-10">
          <a href="https://github.com/castariva18" target="_blank">
            <img
              src="https://pnggrid.com/wp-content/uploads/2022/03/Github-Logo-White.png"
              alt="github"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
