import React from "react";

import { MdClose } from "react-icons/md";

function PopDetail({ handleClose, details }) {
  return (
    <div className="fixed inset-0 z-50 backdrop-blur-sm">
      <div className="flex h-screen justify-center items-center overflow-y-scroll">
        <div className="flex-col bg-darkGrey dark:bg-primaryLight md:w-[500px] md:h-[500px] lg:w-[550px] lg:h-auto py-4 px-8 border-none rounded-2xl">
          <div className="flex-row flex justify-between">
            <div></div>
            <div className="my-4">
              <button
                className=" text-white dark:text-textLight"
                onClick={() => {
                  handleClose(false);
                }}
              >
                <MdClose size={30} />
              </button>
            </div>
          </div>

          <div>
            <img
              src={details.src}
              alt={details.src}
              className="w-[400px] mx-auto rounded-lg"
            />
          </div>
          <div className="font-semibold text-white dark:text-textLight text-2xl mt-4">
            {details.title}
          </div>
          <div className="font-regular text-white dark:text-textLight text-md">
            {details.desc}
          </div>

          <div className="flex flex-wrap gap-4 my-4">
            <div className="rounded-lg  bg-primaryLight dark:bg-primary bg-opacity-50 w-max py-1 px-2 text-sm font-medium text-textLight dark:text-white">
              #{details.tech1}
            </div>
            <div className="rounded-lg bg-primaryLight dark:bg-primary bg-opacity-50 w-max py-1 px-2 text-sm font-medium text-textLight dark:text-white">
              #{details.tech2}
            </div>
            <div className="rounded-lg bg-primaryLight dark:bg-primary bg-opacity-50 w-max py-1 px-2 text-sm font-medium text-textLight dark:text-white">
              #{details.tech3}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopDetail;
