const IMG_CONST = {
  edms: "https://user-images.githubusercontent.com/37837588/210162225-ce544b9b-4c5b-43cc-9f84-1d790c99c9cc.png",
  energymeter:
    "https://user-images.githubusercontent.com/37837588/210162220-17d16b0e-afbb-453d-b4c9-6b8e6e73af01.png",
  envirobox:
    "https://user-images.githubusercontent.com/37837588/210162211-56c11c75-846a-40fb-be61-25cb9e6f0dc2.png",
  harwat:
    "https://user-images.githubusercontent.com/37837588/210162204-abde49bd-b95b-4f1a-b342-4e0226a87f0a.png",
  netta:
    "https://user-images.githubusercontent.com/37837588/210162194-72b7203a-1b27-4f45-8a03-2e67299d09fc.png",
  nuby: "https://user-images.githubusercontent.com/37837588/210162190-8a3f6299-dae0-4bb1-81a0-f97e9e26706c.png",
  pertashop:
    "https://user-images.githubusercontent.com/37837588/210162187-8334be0b-c859-42c8-a391-276460b607b4.png",
  sariban:
    "https://user-images.githubusercontent.com/37837588/210162171-accd45a2-515a-4576-82db-b68a01fd480e.png",
  movie:
    "https://user-images.githubusercontent.com/37837588/210162152-8372af8f-f071-4e71-a5d6-0a4e7c88ef2b.png",
  evote:
    "https://user-images.githubusercontent.com/37837588/210162145-21cdc099-c51b-431b-969b-6ce8a15f76b9.png",
  presence:
    "https://user-images.githubusercontent.com/37837588/210162135-a1130db1-b5c5-43b0-88cf-09662f2d75cd.png",
  discuss:
    "https://user-images.githubusercontent.com/37837588/210162123-bffc1046-56e2-4ad2-8214-6683eee51b76.png",
};

export default IMG_CONST;
