import React, { useEffect } from "react";
import { base64 } from "../../utils/base64";

function PageNotFound(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <div className="h-screen w-full overflow-hidden">
      <div className="flex flex-col justify-center items-center my-20">
        <img
          src={base64.error}
          alt="miro's profile"
          className="rounded-2xl mx-auto w-2/3 md:w-1/3"
        />
        <h2 className="text-white text-3xl">Oops!</h2>
        <h2 className="text-white text-xl">Page Not Found :(</h2>
      </div>
    </div>
  );
}

export default PageNotFound;
