import React from "react";

function Timeline() {
  const timeline = [
    {
      id: 1,
      year: "1999",
      event: [{ idCanon: 1, canon: "I was born 🥚" }],
    },
    {
      id: 2,
      year: "",
      event: [],
    },
    {
      id: 3,
      year: "",
      event: [],
    },
    {
      id: 4,
      year: "2017",
      event: [
        {
          idCanon: 2,
          canon: "Continue my studies at Telkom University.",
        },
      ],
    },
    {
      id: 5,
      year: "2018",
      event: [
        {
          idCanon: 3,
          canon:
            "Head of Study Group Division, Laboratory Assistant, Multimedia Research Laboratory, Telkom University.",
        },
        {
          idCanon: 4,
          canon:
            "Implementation of Data Structure Practicum Assistan D3 RPLA Telkom University.",
        },
      ],
    },
    {
      id: 6,
      year: "",
      event: [],
    },
    {
      id: 7,
      year: "",
      event: [],
    },
    {
      id: 8,
      year: "2019",
      event: [
        {
          idCanon: 5,
          canon:
            "Junior Graphic Design instructor Digital Talent Scholarship at Telkom University.",
        },
        {
          idCanon: 6,
          canon:
            "Intern as IT staff at System And Technology Information – PLN(PT) UIW RIAU DAN KEPULAUAN RIAU.",
        },
        {
          idCanon: 7,
          canon:
            "Deputy Head of the Department of Media and Information, D3 RPLA Student Association.",
        },
      ],
    },
    {
      id: 9,
      year: "2020",
      event: [
        {
          idCanon: 8,
          canon: "Intern as software engineer at PLN(PT) West Java.",
        },
        {
          idCanon: 9,
          canon:
            "Graduated from Telkom University (Informatics Engineering) with 3.4 GPA.",
        },
        {
          idCanon: 10,
          canon: "Start freelance job using Android.",
        },
        {
          idCanon: 11,
          canon: "Make small group programming called SuncodeId.",
        },
        {
          idCanon: 12,
          canon: "Start learning Flutter.",
        },
        {
          idCanon: 13,
          canon:
            "Working as mobile developer at Datacakra Solusi Jaganata(PT).",
        },
      ],
    },
    {
      id: 10,
      year: "",
      event: [],
    },
    {
      id: 11,
      year: "",
      event: [],
    },
    {
      id: 12,
      year: "2021",
      event: [
        {
          idCanon: 14,
          canon: "Start learning Angular.",
        },
        {
          idCanon: 15,
          canon:
            "Formed a software house that would later become a company called Sandhya Acala.",
        },
        {
          idCanon: 16,
          canon:
            "Working as frontend developer at Direktorat Pusat Teknologi Informasi Telkom University.",
        },
        {
          idCanon: 17,
          canon:
            "Datacakra mobile development team delegation to join SENTRA SOLUSI AUTOMA(PT).",
        },
      ],
    },
    {
      id: 13,
      year: "2022",
      event: [
        {
          idCanon: 18,
          canon: "Start learning ReactJS.",
        },
        {
          idCanon: 19,
          canon: "Start learning NextJS.",
        },
        {
          idCanon: 20,
          canon: "Continue my studies at Binus University.",
        },
      ],
    },
    {
      id: 14,
      year: "",
      event: [],
    },
    {
      id: 15,
      year: "",
      event: [],
    },
    {
      id: 16,
      year: "2023",
      event: [
        {
          idCanon: 21,
          canon: "Start learning Python.",
        },
        {
          idCanon: 22,
          canon: "Start learning Data Visualization.",
        },
        {
          idCanon: 23,
          canon: "Start learning Machine Learning.",
        },
        {
          idCanon: 24,
          canon: "Start learning Jupyter Notebook & Google Colab.",
        },
      ],
    },

    {
      id: 17,
      year: "2024",
      event: [
        {
          idCanon: 25,
          canon:
            "Graduated from Binus University(Computer Science) with 3.74 GPA.",
        },
      ],
    },
  ];
  return (
    <div className="flex flex-col justify-center  max-w-screen-lg mx-auto px-8">
      <h2 className="text-3xl sm:text-5xl font-bold text-white dark:text-textLight text-start mb-4">
        Timeline
      </h2>
      <div className="flex justify-center">
        <div className="max-w-2xl w-full relative grid grid-cols-1 sm:grid-cols-2  lg:grid-cols-2 gap-4">
          <div className="absolute h-full w-2 dark:bg-primary bg-primaryLight rounded left-1/2 transform -translate-x-1/2"></div>
          {timeline.map(({ id, year, event }) => (
            <div key={id} className={`relative p-2 col-span-1`}>
              {event.length > 0 ? (
                <div
                  className={`bg-primaryLight rounded p-4 shadow-md shadow-blue-400`}
                >
                  <p className="text-xl font-bold">{year}</p>
                  <ul className="list-disc pl-4">
                    {event.map(({ idCanon, canon }) => (
                      <li key={idCanon} className="mt-2">
                        {canon}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <div></div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Timeline;
