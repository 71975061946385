import React from "react";

import { MdOutlineFileDownload } from "react-icons/md";

function GlimpseOfMe() {
  return (
    <div>
      <div className="flex flex-col h-auto mt-20 mb-10 justify-center items-start max-w-screen-lg mx-auto px-8">
        <div className="flex flex-col justify-center items-start text-white dark:text-textLight">
          <h2 className="text-3xl sm:text-5xl font-bold">Glimpse Of Me</h2>
          <p className="py-4 px-4">
            My name is T. Belmiro Ramadhani but you can call me Miro, I'm 25, I
            live in Bandung city, I'm a frontend/mobile developer with 4 years
            of experience (Professional), I'm very passionate about technology.
            I'm reliable, can work in a team, and can learn quickly my interests
            are in frontend and mobile development. In my free time, I enjoy
            music, playing games, and exploring new things.
          </p>
        </div>
        <div className="flex flex-col justify-center items-start text-white dark:text-textLight mt-4">
          <h2 className="text-3xl sm:text-5xl font-bold ">Bio</h2>
          <ul class="list-disc px-8 mt-2">
            <li className="py-1 text-lg">Name: T.Belmiro Ramadhani</li>
            <li className="py-1 text-lg">Age: 25 years old</li>
            <li className="py-1 text-lg">Nationality: Indonesian</li>
            <li className="py-1 text-lg">City region: Bandung</li>
            <li className="py-1 text-lg">
              Playstore:{" "}
              <a
                href="https://play.google.com/store/apps/dev?id=6551702495617706443"
                className="underline underline-offset-4"
              >
                Sandhya Acala
              </a>{" "}
              &{" "}
              <a
                href="https://play.google.com/store/apps/dev?id=7523064885169137937"
                className="underline underline-offset-4"
              >
                SuncodeId
              </a>
            </li>
          </ul>
          <a
            href="CV.pdf"
            download="CV.pdf"
            className="group text-white dark:text-textLight w-fit m-3 px-6 py-3 flex items-center rounded-md bg-gradient-to-r from-cyan-500 to-blue-500 cursor-pointer hover:scale-105 duration-200"
          >
            Download CV
            <span>
              <MdOutlineFileDownload size={25} className="ml-1" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default GlimpseOfMe;
